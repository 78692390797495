'use client'

import { Button } from '@tebuto/buttons/Button'

export default function GenericError({
    error,
    reset
}: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    console.error(error)

    return (
        <div className="grid min-h-full place-items-center bg-primary-25 px-6 pb-24 sm:pb-48 pt-48 sm:pt-64 lg:px-8">
            <div className="text-center">
                <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">Fehler</h1>
                <p className="mt-6 text-base leading-7 ">
                    Entschuldigung. Da ist etwas schiefgelaufen.
                    <br />
                    Sollte der Fehler erneut auftreten, kontaktieren Sie uns bitte via{' '}
                    <a className="text-primary-600 underline" href="mailto:support@tebuto.de">
                        support@tebuto.de
                    </a>
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Button className="w-max" onClick={reset}>
                        Erneut versuchen
                    </Button>
                </div>
            </div>
        </div>
    )
}
